@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(9, 9, 9);
  background: linear-gradient(
    90deg,
    rgba(9, 9, 9, 1) 0%,
    rgba(4, 23, 13, 1) 100%
  );
  min-height: 100%;
}

#root {
  min-height: 100vh;
}
